.banner{
    width: 1400px;
    max-width: 90%;
    height: 294px;
    background-image: url('./img/banner.png');
    margin: auto;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    &__title{
        text-align: center;
        line-height: 140%;
    }
    &__description{
        max-width: 90%;
        text-align: center;
    }
    &__button{
        font-size: 16px;

    }
}
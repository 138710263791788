@import '../../mixins.scss';

.footer{
    height: 295px;
    margin: auto;
    font-family: var(--font-family-headers);
    background-color: var(--color-black);
    display: flex;
    justify-content: space-between;
    padding-top: 38px;
    flex-wrap: wrap;
    box-sizing: border-box;
    &__container, &__rightcontainer{
        padding-left: 40px;
        padding-right: 40px;
        &--logocontainer{
            margin-left: 15px;
            display: flex;
            align-items: center;
            &-logo{
                margin-right: 7.3px;
            }
        }
        &--disclaimer{
            margin-top: 33px;
            max-width: 450px;
            margin-bottom: 30px;
            &-header{
                font-family: var(--font-family-default);
                color: #ffffff;
                line-height: 140%;
            }
            &-text{
                font-family: var(--font-family-default);
                color: var(--color-eerie-black);
                line-height: 140%;
                font-size: 12px;
            }
        }
    }
    &__rightcontainer{
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &--subcontainer{
            width: 232px;
            max-width: 100%;
            & > h4{}
            &-links{
                margin-bottom: 20px;
                margin-top: 33px;
                
                @include respond(phone) {
                    margin-top: 10px;
                }
                
                &__linkcontainer{
                    max-height: 100px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 7px;
                    &--logo{
                        margin-right: 10px;
                        width: 13px;
                        fill: white;
                        &:hover{
                        }
                    }
                    &--link{
                        font-family: var(--font-family-default);
                        font-size: 12px;
                        line-height: 140%;
                        color: var(--color-eerie-black);
                    }
                }
            }
        }
    }
}

.logo{
    background-color: inherit;
    border-radius: 0;
}
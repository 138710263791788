@import '../../mixins.scss';

.pslide1{
    max-width: 100%;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
    background: linear-gradient(rgba(18, 18, 26, 0.95), rgba(18, 18, 26, 0.95)), url("/Noise_2X.png");
    // box-shadow: inset 0 0 0 1500px rgba(0,0,0,0.);
    // background-repeat: repeat;
    // background-blend-mode: darken;
    &__overlay{
        background-color: var(--color-black);
        position: absolute;
        z-index: -1;
        height: 100%;
        overflow: hidden;
        width: 100%;
        &--ellipse1{
            position: absolute;
            width: 736px;
            height: 736px;
            left: 396px;
            top: 353px;
            
            background: #BD00FF;
            /* Blur - 620 */
            
            filter: blur(310px);
        }
        &--ellipse2{
            position: absolute;
            width: 696px;
            height: 696px;
            left: 758px;
            top: 116px;

            background: #BADAFF;
            /* Blur - 620 */

            filter: blur(310px);
        }
        &--ellipse3{
            position: absolute;
            width: 1129px;
            height: 845px;
            left: -430px;
            top: 81px;

            background: #236BCF;
            /* Blur - 420 */

            filter: blur(210px);
        }
    }
    &__content{
        max-width: 1500px;
        box-sizing: border-box;
        padding-top: 84px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        left: 0;
        right: 0;
        flex-direction: column;
        margin: auto;
        &--top{
            flex-wrap: wrap;
            height: 140px;
            width: 100%;
            padding-left: 35px;
            padding-right: 35px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            margin-bottom: 38px;
            &-header{
                margin-bottom: 0px;
            }
        }
        &--products{
            width: 100%;
            max-width: 1500px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            margin: auto;
            background-color: var(--color-eerie-black4);
            margin-bottom: 51px;
            &-filters{
                min-height: 100%;
                width: 100%;
                gap: 20px;
                max-width: 380px;
                background-color: var(--color-eerie-black5);
                display: flex;
                flex-flow: column;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                border-top-left-radius: inherit;
                border-bottom-left-radius: inherit;
                @include respond(tab-land) {
                    display: none
                }
                &__container{
                    min-height: 50px;
                    width: 300px;
                    background-color: white;
                    filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.04));
                    flex: none;
                    order: 1;
                    flex-grow: 0;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    &--title{
                        text-transform: uppercase;
                        padding-left: 10px;
                        margin-top: 12px;
                        padding-bottom: 12px;
                        color: black;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19.6px;
                        font-family: var(--font-family-headers);
                        border-bottom: solid 1px var(--color-eerie-black4);
                    }
                    &--filters{
                        max-height: 366px;
                        overflow-y: scroll;
                        gap: 5px;
                        display: flex;
                        flex-direction: column;
                        &-filter{
                            display: flex;
                            align-items: center;
                            border: solid 1px #F0F0F5;
                            margin-left: 10px;
                            margin-right: 15px;
                            gap: 5px;
                            & > input[type="checkbox"]{
                                width: 14px;
                                height: 14px;
                                accent-color: var(--color-eerie-black4);
                                border: 1px solid #E1E1EA;
                                border-radius: 3px;
                                
                                &:checked + label{
                                    &:before{
                                        background: red;
                                    }
                                    &:after{
                                        background: red;
                                    }
                                }
                            }
                            &__label{
                                color: black;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 16.8px;
                            }
                        }
                    }
                    &:first-child{
                        margin-top: 40px;
                        @include respond(tab-land) {
                            margin-top: 0px;
                        }
                    }
                }
            }
            &-content{
                box-sizing: border-box;
                margin-top: 41px;
                margin-bottom: 51px;
                margin-left: 42px;
                margin-right: 42px;
                width: 1035px;
                @include respond(phone) {
                    margin-left: 10px;
                    margin-right: 10px;
                }
                &__searchcontainer{
                    box-sizing: border-box;
                    background-color: white;
                    border-radius: 5px;;
                    padding: 10px;
                    width: 100%;
                    &--search{
                        background-color: var(--color-eerie-black4);
                        border: solid 1px var(--color-eerie-black5);
                        width: 100%;
                        height: 42px;
                        padding-left: 28px;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 140%;
                        box-sizing: border-box;
                    }
                }
                &__productcontainer{
                    margin-top: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                }
                &__phonefilter{
                    display: none;
                    @include respond(tab-land) {
                        display: flex;
                    }
                    justify-content: center;
                    width: 100%;
                    border: solid 2px var(--color-black);
                    align-items: center;
                    border-radius: 5px;
                    &--img{
                        width: 18px;
                        height: 18px;
                        margin-right: 5px;
                    }
                    &--text{
                        font-family: var(--font-family-headers);
                        font-style: normal;
                        color: var(--color-black);
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22.4px;
                    }
                }
            }
        }
    }
}

.pslide2{
    padding-top: 50px;
    border-top: solid 1px var(--color-dark-blue);
    border-bottom: solid 1px var(--color-dark-blue);
    padding-bottom: 60px;
}


.product{
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.04);
    overflow: hidden;
    &__topcontainer{
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        &--image{
            flex: 1 1 0;
            width: 160px;
            height: 160px;
            object-fit: cover;
            border-radius: 5px;
        }
        &--content{
            margin-left: 30px;
            max-width: 825px;
            width: 100%;
            &-top{
                display: flex;
                align-items: center;
                width: 100%;
                flex-wrap: wrap;
                justify-content: space-between;
                &__author{
                    font-family: var(--font-family-headers);
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16.8px;
                    margin-left: 11px;
                    color: var(--color-eerie-black2);
                }
                &__dot{
                    color: var(--color-eerie-black3);
                    margin-left: 11px;
                    font-size: 12px;
                    font-family: var(--font-family-headers);
                    font-size: 12px;
                }
                &__date{
                    color: var(--color-eerie-black3);
                    margin-left: 11px;
                    font-size: 12px;
                    font-family: var(--font-family-headers);
                    font-size: 12px;
                }
                &__new{
                    min-height: 29px;
                    padding: 4px 14px 4px 14px;
                    font-family: var(--font-family-headers);
                    font-size: 12px;
                    background-color: var(--color-green);
                    border-radius: 100px;
                    border: none;
                    color: #0D0D0D;
                    margin-left: 11px;
                }
                &__avatar{
                    height: 26px;
                    width: 26px;
                    border-radius: 50%;
                }
            }
            &-titlecontainer{
                &__title{
                    font-size: 21px;
                    line-height: 29.4px;
                    color: var(--color-black);
                    width: 100%;
                    max-width: 570px;
                    font-weight: 600;
                }
            }
            &-labelcontainer{
                &__button{
                    height: 29px;
                    padding: 6px 12px 6px 12px;
                    font-family: var(--font-family-headers);
                    font-size: 12px;
                    background-color: var(--color-green);
                    border-radius: 100px;
                    border: none;
                    color: #1D5AAF;
                    background-color: #BADAFF;
                }
            }
            &-descriptioncontainer{
                &__text{
                    margin-right: 40px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.6px;
                    color: var(--color-eerie-black2);
                }
            }
        }
    }
    &__bottomcontainer{
        padding-top: 10px;
        padding-bottom: 10px;
        border-top: solid 1px var(--color-eerie-black4);
        display: flex;
        align-items: center;
        &--tags{
            flex-wrap: wrap;
            margin-left: 10px;
            row-gap: 10px;
            column-gap: 5px;
            display: flex;
            &-button{
                height: 29px;
                padding: 6px 12px 6px 12px;
                font-family: var(--font-family-headers);
                font-size: 12px;
                background-color: var(--color-eerie-black4);
                border-radius: 100px;
                border: none;
                color: var(--color-eerie-black);
                text-transform: uppercase;
            }
        }
        &--more{

        }
    }
}
.phoneFilter{
    overflow-y: scroll;
    &__container{
        display: flex;
        /* establish flex container */
        flex-direction: column;
        /* make main-axis vertical */
        justify-content: center;
        /* align items vertically, in this case */
        align-items: center;
    }
    position: fixed;
    z-index: 5;
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    background-color: var(--color-eerie-black5);
    &__title{
        display: flex;
        width: 300px;
        margin: auto;
        justify-content: space-between;
        align-items: center;
        &--text{
            margin-top: 40px;
            color: black;
            font-size: 14px;
            margin-bottom: 30px;
            line-height: 19.6px;
            font-family: var(--font-family-headers);
        }
        &--close{
            height: 14px;
            width: 14px;
        }
    }
}
@import '../../mixins.scss';

.aslide1{
    background-repeat: no-repeat;
    max-width: 100%;
    height: 2070px;

    // Bit of a silly way to do it, haven't fixed this page same way there's no longer a height on the index page
    @media screen and (max-width: 1600px) {
        height: 2350px;
    }
    @media screen and (max-width: 1450px) {
        height: 2650px;
    }
    @media screen and (max-width: 1200px) {
        height: 2450px;
    }
    @include respond(phone){
        height: 3200px;
    }
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
    background: linear-gradient(rgba(18, 18, 26, 0.95), rgba(18, 18, 26, 0.95)), url("/Noise_2X.png");
    // box-shadow: inset 0 0 0 1500px rgba(0,0,0,0.);
    // background-repeat: repeat;
    // background-blend-mode: darken;
    &__overlay{
        overflow: hidden;
        background-color: var(--color-black);
        position: relative;
        z-index: -1;
        height: 100%;
        &--ellipse1{
            position: absolute;
            width: 736px;
            height: 736px;
            left: 396px;
            top: 353px;
            
            background: #BD00FF;
            /* Blur - 620 */
            
            filter: blur(310px);
        }
        &--ellipse2{
            position: absolute;
            width: 696px;
            height: 696px;
            left: 758px;
            top: 116px;

            background: #BADAFF;
            /* Blur - 620 */

            filter: blur(310px);
        }
        &--ellipse3{
            position: absolute;
            width: 1129px;
            height: 845px;
            left: -430px;
            top: 81px;

            background: #236BCF;
            /* Blur - 420 */

            filter: blur(210px);
        }
    }
    &__content{
        max-width: 90%;
        position: absolute;
        top: 286px;
        width: 1403px;
        margin-left: 15px;
        margin-right: 15px;
        box-sizing: border-box;
        display: flex;
        left: 0;
        right: 0;
        margin-right: auto;
        margin-left: auto;
        flex-direction: column;
        &--top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 38px;
            width: 100%;
            max-width: 858px;
            flex-direction: column;
            margin: auto;
            & > h1{
                text-align: center;
            }

            & > p{
                margin: 0px;
                font-size: 20px;
                line-height: 140%;
                text-align: center;
                max-width: 420px;
            }
        }
        &--cardcontainer{
            justify-content: center;
            display: flex;
            margin-top: 216px;
            gap: 15px;
            flex-wrap: wrap;
            left: 50%;
            align-items: center;
            &-card{
                box-sizing: border-box;
                padding: 30px;
                height: 260px;
                max-width: 339.5px;
                width: 100%;
                background: rgba(21, 21, 30, 0.7);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                backdrop-filter: blur(31.5px);
                border-radius: 5px;
                &__icon{
                }
                &__title{
                    font-size: 16px;
                    line-height: 22.4px;
                    font-family: var(--font-family-headers);
                    font-weight: 400;
                }
                &__text{
                    margin-top: 15px;
                    font-size: 14px;
                    line-height: 19.6px;
                    color: var(--color-eerie-black);
                }
                & > ul{
                    & > li{
                        font-size: 14px;
                        line-height: 19.6px;
                        color: var(--color-eerie-black);
                    }
                }
            }
        }
        &--social{
            max-width: 90vw;
            margin-top: 219px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            &-left{
                width: 595px;
                & > h1{
    
                }
                & > p{
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 28px;
                    & > a {
                        color: white;
                    }
                }
            }
            &-right{
                display: flex;
                flex-direction: column;
                gap: 11px;
                max-width: 100%;
                &__container{
                    border-radius: 5px;
                    background: rgba(21, 21, 30, 0.7);
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    backdrop-filter: blur(31.5px);
                    padding: 30px;
                    box-sizing: border-box;
                    width: 694px;
                    max-width: 100%;
                    &--top{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        &-left{
                            display: flex;
                            align-items: center;
                            &__image{
                                width: 24px;
                                height: 24px;
                                fill: #DD00FF;
                            }
                            &__platform{
                                font-family: var(--font-family-headers);
                                font-size: 16px;
                                line-height: 22.4px;
                                font-weight: 400;
                                margin-left: 10px;
                            }
                        }
                        &-arrow{
                            width: 24px;
                            height: 24px;
                            fill: #DD00FF;
                        }
                    }
                    &--bottom{
                        width: 100%;
                        &-text{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
.aslide2{
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 60px;
    padding-bottom: 60px;
    border-top: solid 1px #2A2A3C;
    border-bottom: solid 1px #2A2A3C;
}